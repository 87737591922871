@font-face {
  font-family: 'Mint Grotesk';
  src: url('../assets/fonts/MintGrotesk/MintGrotesk-Bold.woff2') format('woff2'),
    url('../assets/fonts/MintGrotesk/MintGrotesk-Bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mint Grotesk';
  src: url('../assets/fonts/MintGrotesk/MintGrotesk-Medium.woff2') format('woff2'),
    url('../assets/fonts/MintGrotesk/MintGrotesk-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mint Grotesk';
  src: url('../assets/fonts/MintGrotesk/MintGrotesk-Regular.woff2') format('woff2'),
    url('../assets/fonts/MintGrotesk/MintGrotesk-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mint Grotesk';
  src: url('../assets/fonts/MintGrotesk/MintGrotesk-Light.woff2') format('woff2'),
    url('../assets/fonts/MintGrotesk/MintGrotesk-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

